import appsignal from "@utils/appsignal";
import request from "./request";

export const getDisciplines = async (): Promise<string[]> => {
  const response = await request.get("/disciplines");
  return response.data;
};

export const getAreasOfExpertise = async (): Promise<string[]> => {
  const response = await request.get("/areas_of_expertise");
  return response.data;
};

export const getKeywords = async (): Promise<{
  disciplines: string[];
  areas_of_expertise: string[];
}> => {
  const response = await request.get("/keywords");
  return response.data;
};

export const createKeyword = async (
  name: string,
  type: "Discipline" | "AreaOfExpertise"
): Promise<void> => {
  try {
    await request.post("/keywords", { name, type });
  } catch (err) {
    appsignal.sendError(err, (span) => {
      span.setAction("keywords#create");
      span.setTags({
        name,
      });
    });
  }
};

export const getKeywordSuggestions = async (
  keywords: string[],
  type: "All" | "Discipline" | "AreaOfExpertise"
): Promise<string[]> => {
  try {
    const response = await request.post("/keywords/suggestions", { keywords, type });
    if (!response) return [];
    return response.data.suggestions;
  } catch (err) {
    if (err?.code !== "ECONNABORTED") {
      appsignal.sendError(err, (span) => {
        span.setAction("keywords#getKeywordsSuggestion");
        span.setTags({
          keywords: keywords.join(";"),
        });
      });
    }
    return [];
  }
};
