import { COLORS, FONTS } from "@constants";
import { ChangeEvent, ReactNode, useEffect, useRef, useState } from "react";
import styled from "styled-components";

type InputType = "checkbox" | "radio";

interface Props {
  label: ReactNode;
  labelFont?: string;
  isChecked: boolean;
  // Used for instances where the checkbox is responsible for select/deselect all.
  // When some, but not all are selected, this checkbox will show the partially-checked state
  isPartiallyChecked?: boolean;
  isDisabled?: boolean;
  isReversed?: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  size?: "md" | "sm";
  type?: InputType;
  "data-testid"?: string;
}

const Checkbox = ({
  label,
  labelFont,
  isChecked,
  isPartiallyChecked,
  isDisabled,
  isReversed = false,
  onChange,
  size = "md",
  type = "checkbox",
  "data-testid": dataTestId,
}: Props) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const checkboxRef = useRef<HTMLInputElement>(null);
  const isFocusedRef = useRef(false);

  const onKeyDown = (e) => {
    if (e.code === "Enter" && isFocusedRef.current) {
      checkboxRef.current?.click();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", onKeyDown);

    return () => document.removeEventListener("keydown", onKeyDown);
  }, []);

  return (
    <Label
      size={size}
      labelFont={labelFont}
      isReversed={isReversed}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      isDisabled={isDisabled}
    >
      <CheckboxContainer
        type={type}
        size={size}
        isChecked={isChecked}
        isPartiallyChecked={isPartiallyChecked}
        isDisabled={isDisabled}
        isHovered={isHovered}
        isFocused={isFocused}
        data-testid={dataTestId}
      >
        <Input
          type={type}
          ref={checkboxRef}
          onFocus={() => {
            isFocusedRef.current = true;
            setIsFocused(true);
          }}
          onBlur={() => {
            isFocusedRef.current = false;
            setIsFocused(false);
          }}
          onChange={onChange}
          checked={isChecked}
          disabled={isDisabled}
        />
        {(() => {
          if (isChecked) return renderCheckIcon(type, size);
          if (isPartiallyChecked || type === "radio") return renderPartialCheckIcon(type, size);
        })()}
      </CheckboxContainer>
      {label}
    </Label>
  );
};

export default Checkbox;

const Label = styled.label`
  display: flex;
  align-items: center;
  ${({ isReversed }) => isReversed && "flex-direction: row-reverse;"}
  ${({ isDisabled }) => isDisabled && "opacity: 0.5;"}
  gap: 8px;
  margin: 0;
  ${({ size }) => (size === "md" ? FONTS.REGULAR_1 : FONTS.REGULAR_2)};
  ${({ labelFont }) => labelFont && labelFont}
  color: ${COLORS.BLACK};
`;
const CheckboxContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ size }) =>
    size === "md"
      ? `
        height: 24px;
        width: 24px;
      `
      : `
        height: 18px;
        width: 18px;
  `}
  border: 1px solid ${({
    isDisabled,
    isHovered,
    isFocused,
    isChecked,
    isPartiallyChecked,
    type,
  }) => {
    if (type !== "checkbox") return "transparent";
    if (isFocused || isChecked || isPartiallyChecked) return COLORS.BLUE_600;
    if (isHovered && !isDisabled) return COLORS.BLACK;
    return COLORS.NEUTRAL_300;
  }};
  border-radius: 4px;
  background-color: ${({ isChecked, isPartiallyChecked, type }) =>
    type === "checkbox" && (isChecked || isPartiallyChecked)
      ? COLORS.BLUE_LIGHT_400
      : COLORS.WHITE};
  cursor: pointer;
`;
const Input = styled.input`
  position: absolute;
  height: 0;
  width: 0;
  opacity: 0;
`;

// These two icons don't seem to be used anywhere else
// (There is a "Tick" icon in the design system, but it is different than this one)
const renderCheckIcon = (type: InputType, size: "md" | "sm") =>
  type === "checkbox" ? (
    <svg
      width={size === "md" ? 18 : 14}
      height={size === "md" ? 18 : 14}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3801 4.08535C15.6091 4.29527 15.6246 4.65109 15.4146 4.8801L7.16465 13.8801C7.06098 13.9932 6.91561 14.059 6.76222 14.0624C6.60883 14.0657 6.46074 14.0062 6.35225 13.8978L2.60225 10.1478C2.38258 9.92808 2.38258 9.57193 2.60225 9.35226C2.82192 9.13259 3.17808 9.13259 3.39775 9.35226L6.73233 12.6868L14.5854 4.11991C14.7953 3.8909 15.1511 3.87543 15.3801 4.08535Z"
        fill={COLORS.BLUE_600}
        stroke={COLORS.BLUE_600}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : (
    <svg
      width={size === "md" ? 22 : 18}
      height={size === "md" ? 22 : 18}
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="9.5" cy="9" r="9" fill={COLORS.BLUE_LIGHT_400} stroke={COLORS.BLUE_600} />
      <circle cx="9.5" cy="9" r="6" fill={COLORS.BLUE_600} stroke={COLORS.BLUE_600} />
    </svg>
  );
const renderPartialCheckIcon = (type: InputType, size: "md" | "sm") =>
  type === "checkbox" ? (
    <svg
      width={size === "md" ? 18 : 14}
      height={size === "md" ? 18 : 14}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="4"
        y1="9"
        x2="14"
        y2="9"
        stroke={COLORS.BLUE_600}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : (
    <svg
      width={size === "md" ? 22 : 18}
      height={size === "md" ? 22 : 18}
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="9.5" cy="9" r={9} fill="white" stroke={COLORS.NEUTRAL_300} />
    </svg>
  );
