import { MessageRecipient } from "@tsTypes/index";
import { ProposalyType } from "@tsTypes/proposaly";
import { Reducer } from "redux";
import {
  CLEAR_MESSENGER_STATE,
  IS_MESSENGER_THREAD_LOADING,
  SET_HAS_UNREAD_MESSAGE,
  SET_IS_MESSENGER_EXPANDED,
  SET_IS_MESSENGER_MINIMIZED,
  SET_IS_MESSENGER_OPEN,
  SET_MESSENGER_DATA,
  SET_MESSENGER_OFFSET_RIGHT,
  SET_MESSENGER_THREAD,
} from "../actions/messengerActions";

export interface MessengerData {
  recipient: MessageRecipient;
  proposalTitle?: string | null;
  proposalyId?: number;
  proposalyType?: ProposalyType;
}

interface InitialState {
  messengerData: MessengerData | Record<string, never>;
  messengerThread: Record<string, any>;
  hasUnreadMessage: boolean;
  isMessengerMinimized: boolean;
  isMessengerOpen: boolean;
  isMessengerExpanded: boolean;
  isMessengerThreadLoading: boolean;
  messengerOffsetRight: number;
}

export const DEFAULT_MESSENGER_OFFSET_RIGHT = 70;

const initialState: InitialState = {
  messengerData: {},
  messengerThread: {},
  hasUnreadMessage: false,
  isMessengerMinimized: false,
  isMessengerOpen: false,
  isMessengerExpanded: false,
  isMessengerThreadLoading: true,
  messengerOffsetRight: DEFAULT_MESSENGER_OFFSET_RIGHT,
};

// eslint-disable-next-line default-param-last
const messengerReducer: Reducer<InitialState> = (state = initialState, action) => {
  switch (action.type) {
    case SET_MESSENGER_DATA:
      return {
        ...state,
        messengerData: action.payload,
      };
    case CLEAR_MESSENGER_STATE:
      return initialState;
    case SET_MESSENGER_THREAD:
      return {
        ...state,
        messengerThread: action.payload,
      };
    case SET_HAS_UNREAD_MESSAGE:
      return {
        ...state,
        hasUnreadMessage: action.payload,
      };
    case SET_IS_MESSENGER_OPEN:
      return {
        ...state,
        isMessengerOpen: action.payload,
      };
    case SET_IS_MESSENGER_MINIMIZED:
      return {
        ...state,
        isMessengerMinimized: action.payload,
      };
    case SET_IS_MESSENGER_EXPANDED:
      return {
        ...state,
        isMessengerExpanded: action.payload,
      };
    case IS_MESSENGER_THREAD_LOADING:
      return {
        ...state,
        isMessengerThreadLoading: action.payload,
      };
    case SET_MESSENGER_OFFSET_RIGHT: {
      return {
        ...state,
        messengerOffsetRight: action.payload,
      };
    }
    default:
      return state;
  }
};

export default messengerReducer;
