import { TextButton, TruncatedText } from "@components/library";
import { COLORS, FONTS, STYLES } from "@constants";
import { setMessengerState } from "@redux/actions/messengerActions";
import { MessengerData } from "@redux/reducers/messengerReducer";
import { User } from "@tsTypes/schema";
import { SponsorLicenseType } from "@tsTypes/sponsorProfiles";
import { UserRole } from "@tsTypes/users";
import { ReactNode, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import ProfilePicture from "../ProfilePicture/ProfilePicture";
import { LicenseTag, SuperAdminTag } from "../Tags/users";

interface BaseProps {
  user: Pick<User, "id" | "image"> & {
    name: string;
    title?: string;
    location?: string;
    profile_info?: {
      title: string | null;
      location?: string;
      license?: SponsorLicenseType;
      is_super_admin?: boolean;
    };
  };
  size?: "sm" | "md" | "lg" | "xl";
  tooltipPosition?: "top" | "bottom" | "left" | "right";
}

interface ScientistProps extends BaseProps {
  userRole?: UserRole.SCIENTIST;
  isLinkDisabled?: boolean;
  withMessageButton?: boolean;
  messengerData?: MessengerData;
}

interface SponsorProps extends BaseProps {
  userRole: UserRole.SPONSOR;
  isUserHidden?: boolean;
  showSponsorRoles?: boolean;
}

const UserPreview = ({
  user,
  size = "sm",
  userRole = UserRole.SCIENTIST,
  tooltipPosition = "top",
  ...props
}: ScientistProps | SponsorProps) => {
  const [isMessageButtonHovered, setIsMessageButtonHovered] = useState(false);

  const dispatch = useDispatch();

  const { isLinkDisabled, withMessageButton, messengerData } = props as ScientistProps;
  const { isUserHidden, showSponsorRoles } = props as SponsorProps;

  const handleMessageClick = (e) => {
    e.preventDefault();

    if (messengerData) {
      dispatch(setMessengerState(messengerData));
    }
  };

  let association: ReactNode;
  if (userRole === UserRole.SPONSOR) {
    association = (
      <UserAssociation size={size}>
        {isUserHidden ? "Reviewer" : user.profile_info?.title ?? user.title}
      </UserAssociation>
    );
  }

  if (userRole === UserRole.SCIENTIST) {
    association = (
      <TruncatedText
        text={`${user.profile_info?.title ?? user.title ?? "Researcher"}${
          showSponsorRoles ? "" : `, ${user.profile_info?.location ?? user.location}`
        }`}
        font={size === "sm" || size === "md" ? FONTS.REGULAR_3 : FONTS.REGULAR_2}
        tooltipPosition={tooltipPosition}
      />
    );
  }

  const asLink = userRole === UserRole.SCIENTIST && !isLinkDisabled && "profile_id" in user;

  return (
    <Container data-testid="user-preview">
      <StyledLink
        as={asLink ? Link : "div"}
        to={asLink ? `/profile/${user.profile_id}` : null}
        asLink={asLink}
        target="_blank"
        rel={"noreferrer"}
        size={size}
        isMessageButtonHovered={isMessageButtonHovered}
      >
        <ProfilePicture size={size} user={user} isUserHidden={isUserHidden} />
        <Text>
          <TopRow>
            <Name size={size} className="name" data-testid="name">
              {isUserHidden ? "Team Member" : user.name}
            </Name>
            {withMessageButton && messengerData ? (
              <TextButton
                text="Message"
                iconName="Message"
                iconPosition="left"
                margin="4px 0 0 12px"
                size="sm"
                onMouseEnter={() => setIsMessageButtonHovered(true)}
                onMouseLeave={() => setIsMessageButtonHovered(false)}
                onClick={(e) => handleMessageClick(e)}
                data-testid="user-preview-message-button"
              />
            ) : (
              showSponsorRoles && (
                <>
                  {user.profile_info?.is_super_admin && (
                    <SuperAdminTagContainer>
                      <SuperAdminTag />
                    </SuperAdminTagContainer>
                  )}
                  {Boolean(user?.profile_info?.license) && (
                    <PremiumTagContainer>
                      <LicenseTag type={user.profile_info?.license as SponsorLicenseType} />
                    </PremiumTagContainer>
                  )}
                </>
              )
            )}
          </TopRow>
          {association}
        </Text>
      </StyledLink>
    </Container>
  );
};

export default UserPreview;

const Container = styled.div`
  width: auto;
  line-height: normal;
`;
const StyledLink = styled(Link)`
  display: inline-grid;
  grid-template-columns: ${({ size }) => {
    switch (size) {
      case "sm": {
        return "36px 1fr";
      }
      case "md": {
        return "50px 1fr";
      }
      case "lg": {
        return "68px 1fr";
      }
      case "xl": {
        return "96px 1fr";
      }
    }
  }};
  grid-gap: ${({ size }) => {
    switch (size) {
      case "sm":
      case "md":
        return "0 8px";
      case "lg":
      case "xl":
        return "0 16px";
    }
  }};
  align-items: center;
  width: auto;
  color: ${COLORS.BLACK};
  &:hover {
    text-decoration: none;
    color: ${COLORS.BLACK};
    & .name {
      text-decoration: ${({ asLink, isMessageButtonHovered }) =>
        asLink && !isMessageButtonHovered ? "underline" : "none"};
    }
  }
  &:focus {
    text-decoration: none;
    color: ${COLORS.BLACK};
  }
`;
const Text = styled.div`
  min-width: 0;
`;
const TopRow = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;
const SuperAdminTagContainer = styled.div`
  margin-bottom: -4px;
  pointer-events: none;
`;
const PremiumTagContainer = styled.div`
  margin-bottom: -4px;
  pointer-events: none;
`;
const Name = styled.div`
  ${STYLES.ONE_LINE}
  ${({ size }) => {
    switch (size) {
      case "sm":
      case "md": {
        return FONTS.TAG_SEMIBOLD_1;
      }
      case "lg":
      case "xl": {
        return FONTS.HEADING_5_SEMIBOLD;
      }
    }
  }};
  margin-top: 4px;
`;
const UserAssociation = styled.div`
  ${STYLES.TWO_LINES}
  ${({ size }) => {
    switch (size) {
      case "sm":
      case "md": {
        return FONTS.REGULAR_3;
      }
      case "lg":
      case "xl": {
        return FONTS.REGULAR_2;
      }
    }
  }};
`;
