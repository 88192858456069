import {
  MessageAttachmentPartial,
  MessageThreadLookupResponse,
  MessageThreadsShowResponse,
} from "@tsTypes/index";
import { MessageThreadsIndexResponse } from "@tsTypes/message_threads";
import { ProposalyType } from "@tsTypes/proposaly";
import { MessageAttachment } from "@tsTypes/schema";
import { Dispatch, SetStateAction } from "react";
import { InboxFilterOptions } from "src/views/Inbox/Sidebar/Sidebar";
import request from "./request";

export type MessageAttachmentAsJson = MessageAttachment & {
  file_url: string;
  thumbnail_url: string;
  download_url: string;
};

export const getMessageThreads = async (
  options: InboxFilterOptions
): Promise<MessageThreadsIndexResponse> => {
  const response = await request.get("/message_threads", {
    params: {
      options: {
        sort_by: options.sortBy,
        search_filter: options.searchFilter,
        message_type_filter: options.requestIdFilter,
        page_number: options.pageNumber,
      },
    },
  });

  return response.data;
};

export const getMessages = async (messageThreadId: number): Promise<MessageThreadsShowResponse> => {
  const response = await request.get(`/message_threads/${messageThreadId}`);

  return response.data;
};

export const addMessageToThread = async ({
  messageThreadId,
  content,
  attachmentIds,
}: {
  messageThreadId: number;
  content: string;
  attachmentIds: number[];
}) => {
  const response = await request.post(`/message_threads/${messageThreadId}/add_message`, {
    message_thread_id: messageThreadId,
    content,
    attachment_ids: attachmentIds,
    source: window.location.pathname,
  });

  return response.data;
};

export const setDraftMessage = async ({
  proposalyId,
  proposalyType,
  content,
  attachmentIds,
}: {
  proposalyId: number;
  proposalyType: ProposalyType;
  content: string;
  attachmentIds: number[];
}) => {
  const response = await request.post(`/draft_messages`, {
    proposaly_id: proposalyId,
    proposaly_type: proposalyType,
    content,
    attachment_ids: attachmentIds,
  });

  return response.data;
};

export const getDraftMessage = async ({
  proposalyId,
  proposalyType,
}: {
  proposalyId: number;
  proposalyType: ProposalyType;
}): Promise<
  | undefined
  | {
      id: number;
      content: number;
      message_attachments: MessageAttachmentPartial[];
    }
> => {
  try {
    const response = await request.get(`/draft_messages`, {
      params: {
        proposaly_id: proposalyId,
        proposaly_type: proposalyType,
      },
    });

    return response.data;
  } catch (error) {
    if (error.response?.status === 404) {
      return undefined;
    }
    throw error;
  }
};

// Returns message_thread and messages or undefined if no message thread exists
export const getMessageByUserProposal = async ({
  userId,
  proposalyType,
  proposalyId,
}: {
  userId: number;
  proposalyType: ProposalyType;
  proposalyId: number;
}): Promise<MessageThreadLookupResponse | undefined> => {
  try {
    const response = await request.get(`/message_threads/lookup`, {
      params: {
        user_id: userId,
        proposaly_type: proposalyType,
        proposaly_id: proposalyId,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response?.status === 404) {
      return undefined;
    }

    throw error;
  }
};

export const getMessageByRecipients = async ({
  scientistUserId,
  recipientUserId,
}): Promise<MessageThreadLookupResponse | undefined> => {
  try {
    const response = await request.get("/message_threads/lookup", {
      params: {
        scientist_user_id: scientistUserId,
        recipient_user_id: recipientUserId,
      },
    });
    return response?.data;
  } catch (error) {
    if (error.response?.status === 404) {
      return undefined;
    }

    throw error;
  }
};

export const createMessageThread = async ({
  proposalyType,
  proposalyId,
  userId,
  content,
  attachmentIds,
}: {
  proposalyType?: ProposalyType;
  proposalyId?: number;
  userId: number;
  content: string;
  attachmentIds: number[];
}) => {
  const response = await request.post(`/message_threads`, {
    proposaly_type: proposalyType,
    proposaly_id: proposalyId,
    user_id: userId,
    content,
    attachment_ids: attachmentIds,
  });
  return response.data;
};

export const uploadAttachment = async (
  file: File,
  abortSignal: AbortSignal,
  setUploadPercentage: Dispatch<SetStateAction<number | undefined>>
): Promise<MessageAttachmentAsJson | undefined> => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("filename", file.name);
    const response = await request.post("/message_attachments", formData, {
      signal: abortSignal,
      onUploadProgress: (progressEvent) => {
        const uploadPercentage = (progressEvent.loaded / progressEvent.total) * 100;
        setUploadPercentage(uploadPercentage);
      },
    });

    return response.data;
  } catch (error) {
    if (!abortSignal.aborted) {
      throw error;
    }
  }
};
